<template>
    <v-app id="inspire">
        <v-navigation-drawer
                v-model="drawer"
                app
                width="180"
                dark
                color="green darken-4"
        >
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h6 " align="center">
                        ADM
                    </v-list-item-title>

                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list
                    dense
                    nav
                    shaped

            >
                <v-list-item
                        v-for="item in items"
                        :key="item.title"
                        :to="item.to"
                        active-class="yellow darken-3"

                >
                    <v-list-item-icon class="mr-2">
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

            </v-list>

        </v-navigation-drawer>

        <v-app-bar app flat color="green darken-4" dark height="50">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title>Phongsavanh Insurance (APA) Co.,Ltd</v-toolbar-title>
            <v-spacer></v-spacer>

                <v-btn icon @click="logout">
                    <v-icon>mdi-power</v-icon>
                </v-btn>
        </v-app-bar>

        <v-main class="ma-2">
            <router-view></router-view>
        </v-main>
    </v-app>
</template>

<script>
    export default {
        name: "MainLayout",
        data: () => ({
            drawer: null,
            items: [
                {title: 'Dashboard', icon: 'mdi-human-male-board-poll', to: '/'},
                {title: 'Medical Claim', icon: 'mdi-hospital-building', to: '/medical'},
                {title: 'Petty Cash', icon: 'mdi-cash-fast', to: '/petty'},
                {title: 'Payment Request', icon: 'mdi-file-sign', to: '/payment'},
                {title: 'Leave Monitor', icon: 'mdi-plane-car', to: '/leave'},
                {title: 'Booking', icon: 'mdi-car-clock', to: '/booking'},

            ],
        }),
        methods:{
            async logout() {
                await this.$store.dispatch('logout')

            },
        }
    }
</script>

<style scoped>

</style>