<template>
    <Doughnut
            :options="chartOptions"
            :data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"


    />
</template>

<script>
    import { Doughnut } from 'vue-chartjs'
    import ChartDataLabels from 'chartjs-plugin-datalabels';
    import {
        Chart as ChartJS,
        Title,
        Tooltip,
        Legend,
        ArcElement,
        CategoryScale
    } from 'chart.js'

    ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale,ChartDataLabels)

    export default {
        name: 'DoughnutChart',
        components: {
            Doughnut
        },
        props: {
            chartId: {
                type: String,
                default: 'doughnut-chart'
            },
            datasetIdKey: {
                type: String,
                default: 'label'
            },
            width: {
                type: Number,
                default: 200
            },
            height: {
                type: Number,
                default: 200
            },
            cssClasses: {
                default: '',
                type: String
            },
            styles: {
                type: Object,
                default: () => {}
            },
            plugins: {
                type: Object,
                default: () => {}
            },
            chartData: {
                type:Object,
                required:true
            },
        },
        data() {
            return {

                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                        legend: {
                            position: 'top'
                        },
                        datalabels: {
                            // color: "black",

                            anchor: 'center',
                            align: 'center',
                            display: 'auto',
                            font: {
                                weight: "bold",
                                size: 10
                            },
                            formatter: (value, ctx) => {
                                let sum = 0;
                                let dataArr = ctx.chart.data.datasets[0].data;
                                dataArr.map(data => {
                                    sum += data;
                                });
                                let percentage = (value*100 / sum).toFixed(2)+"%";
                                return percentage;
                            },
                        }
                    }
                },

            }
        }
    }
</script>
