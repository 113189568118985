<template>
    <LineChartGenerator

            :options="chartOptions"
            :data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
    />


</template>

<script>
    import {Line as LineChartGenerator} from 'vue-chartjs'
    import ChartDataLabels from 'chartjs-plugin-datalabels';
    import {
        Chart as ChartJS,
        Title,
        Tooltip,
        Legend,
        LineElement,
        LinearScale,
        CategoryScale,
        PointElement,

    } from 'chart.js'

    ChartJS.register(
        Title,
        Tooltip,
        Legend,
        LineElement,
        LinearScale,
        CategoryScale,
        PointElement, ChartDataLabels
    )

    export default {
        name: 'LineChart',
        components: {
            LineChartGenerator
        },
        props: {
            chartId: {
                type: String,
                default: 'line-chart'
            },
            datasetIdKey: {
                type: String,
                default: 'label'
            },
            width: {
                type: Number,
                default: 400
            },
            height: {
                type: Number,
                default: 400
            },
            cssClasses: {
                default: '',
                type: String
            },
            styles: {
                type: Object,
                default: () => {
                }
            },
            plugins: {
                type: Array,
                default: () => []
            },
            chartData: {
                type: Object,
                default: () => {
                }
            },

        },
        data() {
            return {
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: true,
                    scales: {
                        y: {
                            min: 0,
                        }
                    },
                    plugins: {
                        datalabels: {
                            // color: "black",
                            anchor: 'end',
                            align: 'left',
                            display: 'auto',
                            font: {
                                weight: "bold",
                                size: 10
                            },
                            formatter: function(value, context) {
                                var index = context.dataIndex
                                var previousValue = context.dataset.data[index - 1] || context.dataset.data[index ]
                                var growthPercentage = ((value - previousValue) / previousValue) * 100
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +'('+ growthPercentage.toFixed(0)+'%)';
                                // return value+ ' LAK';
                            }

                        },

                    },




                },



            }
        }

    }
</script>
