import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MainLayout from "@/views/MainLayout";
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: MainLayout,
    children:[
      {
        path:'/',component:HomeView,
        meta: {requiresAuth: true}
      },
      {
        path: '/medical',component: ()=>import('@/views/medical/listView'),meta: {requiresAuth: true}
      },
      {
        path: '/medical/search',component: ()=>import('@/views/medical/searchForm'),meta: {requiresAuth: true}
      },

      {
        path: '/medical/track/:id/:year',component: ()=>import('@/views/medical/medicalTracking'),meta: {requiresAuth: true}
      },
      {
        path: '/petty',component: ()=>import('@/views/petty/indexView'),meta: {requiresAuth: true}
      },
      {
        path: '/petty/view/:id',component: ()=>import('@/views/petty/formView'),meta: {requiresAuth: true}
      },
      {
        path: '/petty/create',component: ()=>import('@/views/petty/createView'),meta: {requiresAuth: true}
      },
      {
        path: '/petty/track',component: ()=>import('@/views/petty/trackView'),meta: {requiresAuth: true}
      },
      {
        path: '/petty/report',component: ()=>import('@/views/petty/reportView'),meta: {requiresAuth: true}
      },
      {
        path: '/payment',component: ()=>import('@/views/payment/indexView'),meta: {requiresAuth: true}
      },
      {
        path: '/payment/create',component: ()=>import('@/views/payment/createView'),meta: {requiresAuth: true}
      },
      {
        path: '/payment/report',component: ()=>import('@/views/payment/reportView'),meta: {requiresAuth: true}
      },
      {
        path: '/payment/view/:id',component: ()=>import('@/views/payment/detailView'),meta: {requiresAuth: true}
      },
      {
        path: '/payment/liquidation/:id',component: ()=>import('@/views/payment/liquidationView'),meta: {requiresAuth: true}
      },
      {
        path: '/leave',component: ()=>import('@/views/leave/leaveView'),meta: {requiresAuth: true}
      },
      {
        path: '/booking', component: () => import( '@/views/booking/bookView')
      },
    ]

  },
  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/LoginView.vue')
  },

  {
    path:'*',
    component:() => import( '@/views/NotFound')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
