<template>
  <div>
    <loader-component></loader-component>
    <router-view></router-view>
  </div>
</template>

<script>

import LoaderComponent from "@/components/LoadingComponent";
export default {
  name: 'App',
  components: {LoaderComponent},
  data: () => ({
    //
  }),
};
</script>
