<template>
    <div class="home">
        <v-card>
            <v-card-title>
                Dashboard
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" md="4" lg="3">
                        <v-banner class="title">Current Petty Cash Installment</v-banner>
                        <DoughnutChart v-if="chart_data_loaded" :chart-data="petty_data"/>
                    </v-col>
                    <v-col cols="12" sm="6" md="8" lg="9">
                        <v-banner class="title">Tracking of Petty Cash last 5 installment</v-banner>
                        <bar-chart v-if="chart_data_loaded" :chart-data="petty_track" :height="130"></bar-chart>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-banner class="title">Monthly Medical Claim</v-banner>
                        <line-chart v-if="chart_data_loaded" :chart-data="medical_claim" :height="80"></line-chart>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>

</template>

<script>

    import DoughnutChart from "@/components/DoughnutChart";
    import BarChart from "@/components/BarChart";
    import LineChart from "@/components/LineChart";

    export default {
        name: 'HomeView',
        data: () => ({
            chart_data_loaded: true,
            petty_data: {
                labels: ['Balance', 'Expenses'],
                datasets: [
                    {
                        backgroundColor: ['#A5D6A7', '#EF9A9A'],
                        data: [1500000, 500000]
                    }
                ]
            },
            petty_track: {
                labels: ['Petty Cash Tracking'],
                datasets: [
                    {
                        label: 'Cash In',
                        backgroundColor: ['#A5D6A7'],
                        data: [0, 0, 0]
                    },
                    {
                        label: 'Cash Out',
                        backgroundColor: ['#EF9A9A'],
                        data: [0, 0, 0]
                    }

                ]
            },
            medical_claim: {
                labels: [],
                datasets: [
                    {
                        label: 'Medical Claim',
                        backgroundColor: '#E57373',
                        lineTension:0.4,
                        data: []
                    },
                ]
            }
        }),
        components: {LineChart, BarChart, DoughnutChart},
        mounted() {
            this.getChart()
        },
        methods: {
            async getChart() {
                this.chart_data_loaded = false
                try {
                    const r = await window.axios.get('dashboard')


                    this.petty_data.datasets[0].data = [parseFloat(r.data.petty_cast.total_in - r.data.petty_cast.total_out), parseFloat(r.data.petty_cast.total_out)]


                    this.petty_track.labels = r.data.petty_track.label
                    this.petty_track.datasets[0].data = r.data.petty_track.cash_in
                    this.petty_track.datasets[1].data = r.data.petty_track.cash_out

                    this.medical_claim.labels=r.data.request_payment.title
                    this.medical_claim.datasets[0].data=r.data.request_payment.amount
                    console.log(this.request_payment)
                    this.chart_data_loaded = true


                } catch (e) {
                    console.log(e)
                }
            },
        }
    }
</script>
