import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#1B5E20',
                secondary: '#F9A825',
                accent: '#FFB74D',
                error: '#B71C1C',
            },
        },

    },
});
